import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Carousel } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Renovations = () => (
  <Layout>
    <SEO
      title="Renovations"
      description="Create space for a growing family, open up the living spaces, refresh an old kitchen, re-clad and insulate to give an old house new life."
    />
    <section>
      <div className="container">
        <h1 className="section-heading">Renovations</h1>
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <p>
                Create additional space for a growing family, get that
                indoor-outdoor flow you have always dreamed of, open up the
                living spaces, refresh an outdated kitchen, re-clad and insulate
                to give an old farm house new life, or update and revamp to add
                value before you sell. Whatever your reason for renovating, you
                can be sure that the transformation will be beautiful when
                building with Devenny Construction.
              </p>
              <p>
                A professional renovation will add value and character to your
                home. Our team has the expertise and experience to carry out
                major renovations to existing homes as well as smaller cost
                effective refurbishments.
              </p>
              <p>
                <Link className="font-weight-bold" to="/contact/">
                  Get in touch
                </Link>{" "}
                for a free consultation and sound building advice.
              </p>
            </div>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0 mb-4">
            <Carousel>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/renovations/007.jpeg"
                  alt="Path to newly renovated house"
                  loading="eager"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  transformOptions={{ fit: "contain" }}
                  src="../images/renovations/001.jpeg"
                  alt="House renovation"
                  loading="eager"
                />
              </Carousel.Item>

              {/* <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/renovations/009.jpeg"
                  alt="Preparing the cladding"
                  loading="eager"
                />
              </Carousel.Item> */}
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/renovations/008.jpeg"
                  alt="Existing house"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/renovations/lansdowne/1.webp"
                  alt="Before deck"
                  loading="eager"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/renovations/lansdowne/2.webp"
                  alt="Extending the foundation"
                  loading="eager"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/renovations/lansdowne/3.webp"
                  alt="Finished deck"
                  loading="eager"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Renovations
